
import { EstimationClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import store from '@/store';

@Options({})
export default class Layout extends Vue {

    canNavigate: boolean = false;

    created() {
        this.checkIfNavbarIsNavigable();
    }

    @Watch('$store.state.estimationIdentifier')
    checkIfNavbarIsNavigable() {
        if(store.state.estimationIdentifier) {
            EstimationClient.getIfFinished(store.state.estimationIdentifier)
            .then(x => {
                this.canNavigate = x;
            })
        }
    }

    navigateTo(route) {
        if(!store.state.estimationIdentifier || !this.canNavigate)
            return;

        this.$router.push(route);
    }

    get activeStep(){
        if(this.$route.name == "footwear_componentsWeights")
            return 1;
        else if(this.$route.name == "footwear_componentsMaterial")
            return 2;
        else if(this.$route.name == "footwear_chemicals")
            return 3;
        else if(this.$route.name == "footwear_accessories")
            return 4;
        else if(this.$route.name == "footwear_processes")
            return 5;
        else if(this.$route.name == "footwear_packaging")
            return 6;
        else if(this.$route.name == "footwear_summary")
            return 7;

        return 0;
    }
}
